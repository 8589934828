const pages = [
  {
    prompt: 'did you?',
    buttons: [
      {
        text: 'Test Positive',
        indexTo: 2,
      },
      {
        text: 'Have An Exposure',
        indexTo: 1,
      },
    ],
  },
  {
    prompt: 'your status',
    buttons: [
      {
        text: 'Fully Vaccinated',
        indexTo: 3,
      },
      {
        text: 'Not Fully Vaccinated',
        indexTo: 4,
      },
      {
        text: 'Had COVID Within 90 Days',
        indexTo: 5,
      },
    ],
  },
  {
    prompt: 'the cdc says',
    heading: 'Stay Home',
    text: [
      'Stay home for at least 5 days and isolate from others in your home.',
      'Wear a well-fitted mask if you must be around others in your home.',
      'If you had symptoms, end isolation after 5 full days if you are fever-free for 24 hours (without the use of fever-reducing medication) and your symptoms are improving.',
      'If you did not have symptoms, end isolation after at least 5 full days after your positive test.',
      'If you were severely ill with COVID-19, you should isolate for at least 10 days. Consult your doctor before ending isolation.',
      'Wear a well-fitted mask for 10 full days any time your are around others inside your home or in public. Do not go places where you are unable to wear a mask.',
      'Avoid travel.',
      'Avoid being around people who are at high risk',
    ],
  },
  {
    prompt: 'the cdc says',
    heading: 'No Quarantine',
    text: [
      'You do not need to stay home unless you develop symptoms.',
      `Even if you don't develop symptoms, get tested at least 5 days after you last had close contact with someone with COVID-19.`,
      'Watch for symptoms until 10 days after you last had close contact with someone with COVID-19.',
      'If you develop symptoms, isolate immediately and get tested. Continue to stay home until you know the results. Wear a well-fitted mask around others.',
      'Take precautions until day 10',
      'Wear a well-fitted mask for 10 full days any time your are around others inside your home or in public. Do not go places where you are unable to wear a mask.',
      'Take precautions if traveling',
      'Avoid being around people who are at high risk',
    ],
  },
  {
    prompt: 'the cdc says',
    heading: 'Quarantine for at least 5 days.',
    text: [
      'Stay home and quarantine for at least 5 full days.',
      'Wear a well-fitted mask if you must be around others in your home.',
      'Do not travel.',
      `Even if you don't develop symptoms, get tested at least 5 days after you last had close contact with someone with COVID-19.`,
      'Watch for symptoms until 10 days after you last had close contact with someone with COVID-19.',
      'It is best to avoid travel until a full 10 days after you last had close contact with someone with COVID-19.',
      'Wear a well-fitted mask for 10 full days any time your are around others inside your home or in public. Do not go places where you are unable to wear a mask.',
      'If you must travel during days 6-10, take precautions.',
      'Avoid being around people who are at high risk',
    ],
  },
  {
    prompt: 'the cdc says',
    heading: 'No Quarantine',
    text: [
      'You do not need to stay home unless you develop symptoms.',
      'If you develop symptoms isolate immediatly and get tested.',
      'Wear a well-fitted mask for 10 full days any time your are around others inside your home or in public. Do not go places where you are unable to wear a mask.',
      'Avoid travel.',
      'Avoid being around people who are at high risk',
    ],
  },
];

export default pages;
