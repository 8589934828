import styled, { createGlobalStyle } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import Body from '../body/Body';
import Modal from '../modal/Modal';
import Alert from '../modal/Alert';

import useModal from '../modal/useModal';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

const GlobalStyle = createGlobalStyle`
html {
  margin: 0;
  padding: 0;
  background: ${colors.darkGrey};
  font-family: ${fonts.regular.fontFamily};
  font-weight: ${fonts.regular.medium.weight};
  font-style: ${fonts.regular.medium.style};
  color: ${colors.lightGrey};
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}
#root {
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: left;
}
`;

const AppWrapper = styled.main`
  max-width: 600px;
  flex-grow: 1;
  display: grid;
  grid-template-rows: 2em 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: "title" "body";
  height: 100vh;
  overflow: hidden;
`;

const Title = styled.h2`
  margin: 0;
  color: ${colors.secondary};
  font-family: ${fonts.regular.fontFamily};
  font-weight: ${fonts.regular.extraBoldItalic.weight};
  font-style: ${fonts.regular.extraBoldItalic.style};
  grid-area: title;
  font-size: 1.8em;
`;

const Footer = styled.footer`
  position: fixed;
  background: linear-gradient(0deg, rgba(23,26,33,1) 0%, rgba(0,0,0,0) 100%);
  height: 4em;
  width: 100vw;
  bottom: 0;
`;

const P = styled.p`
  margin: 1.5em 1em 0 1em;
  line-height: 1.2em;
  font-size: 1.1em;
  color: ${colors.primary};
  cursor: pointer;

  &:hover {
    color: ${colors.secondary};
  }
`;

function App () {
  const { isShowing, toggleModal } = useModal();

  return (
    <>
      <AppWrapper>
        <GlobalStyle />
        <Title>should i quarantine?</Title>
        <BrowserRouter>
          <Body />
        </BrowserRouter>
      </AppWrapper>
      <Footer>
        <P onClick={toggleModal}>disclaimer</P>
      </Footer>
      <Modal isShowing={isShowing} hide={toggleModal} displayElem={<Alert />} />
    </>
  );
}

export default App;
