import { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTransition, animated, easings } from 'react-spring';

import pages from './pages';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

const BodyWrapper = styled(animated.div)`
  justify-self: center;
  padding: 4em 1.5em 0 1em;
  grid-area: body;
  display: grid;
  grid-template-areas: "prompt" "quiz";
  grid-template-rows: 4em 1fr;
  max-width: 350px;
  width: 85%;
  flex-grow: 1;
  text-align: center;
  height: auto;
  overflow: hidden;
  will-change: opacity;
`;

const BodyPrompt = styled.h1`
  grid-area: 'prompt';
  margin: 0;
  color: ${colors.primary};
  font-family: ${fonts.regular.fontFamily};
  font-weight: ${fonts.regular.extraBoldItalic.weight};
  font-style: ${fonts.regular.extraBoldItalic.style};
  grid-area: prompt;
  font-size: 3.5em;
`;

const ButtonWrapper = styled.div`
  grid-area: quiz;
  display: grid;
  grid-auto-rows: 3em;
  grid-row-gap: 3em;
  align-self: start;
  margin: 4em 0 0 0;
`;

const Button = styled.button`
  margin: .4em;
  background-color: transparent;
  border: .2em solid ${colors.mediumGrey};
  border-radius: 1em;
  color: ${colors.lightGrey};
  font-family: ${fonts.mono.fontFamily};
  text-transform: uppercase;
  font-size: 1.1em;
  height: 2.8em;
  padding-bottom: .1em;

  &:hover {
    background-color: ${colors.lightGrey};
    border-color: ${colors.accent};
    color: ${colors.darkGrey};
  }

  &:active {
    background-color: ${colors.mediumGrey};
    color: ${colors.primary};
  }
`;

const TextScroll = styled.div`
  grid-area: quiz;
  overflow-y: scroll;

  &:before {
    content: '';
    position: absolute;
    background-color: white;
    height: 50px;
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(23,26,33,1) 0%, rgba(0,0,0,0) 100%);
  }
`;

const TextWrapper = styled.div`
    grid-area: quiz;
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 2em;
    align-self: start;
    text-align: left;
    overflow-y: scroll;
    margin: 5em 0 10em 0;
`;

const TextHeading = styled.h3`
  font-family: ${fonts.mono.fontFamily};
  text-transform: uppercase;
  padding-bottom: 1em;
  border-bottom: 2px solid ${colors.accent};
  width: 12em;
`;

const Line = styled.p`
  margin: 0;
  line-height: 1.2em;
  font-size: 1.1em;
`;

function Body () {
  const [currentIndex, setIndex] = useState(0);
  const [displayIndex, setDisplayIndex] = useState(0);

  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const transitions = useTransition(currentIndex, {
    from: { opacity: 0, x: 100 },
    enter: { opacity: 1, x: 0 },
    leave: { opacity: 0, x: -100 },
    config: { duration: 700, friction: 10, bounce: 1000, easing: easings.easeInOutQuad },
    exitBeforeEnter: true,
    onRest: () => setDisplayIndex(currentIndex),
  });

  useEffect(() => {
    if (pathname === '/') return setIndex(0);
    const parsedPathname = parseInt(pathname.replace('/', ''), 10);

    if (typeof parsedPathname === 'number') return setIndex(parsedPathname);

    return setIndex(0);
  }, [pathname]);

  function generatePages () {
    const { prompt, buttons, text, heading } = pages[displayIndex];

    if (buttons) {
      return (
        <>
          <BodyPrompt>{prompt}</BodyPrompt>
          <ButtonWrapper>
            {buttons.map((button) => <Button onClick={() => navigate(`/${button.indexTo}`)}>{button.text}</Button>)}
          </ButtonWrapper>
        </>
      );
    }
    return (
      <>
        <BodyPrompt>{pages[currentIndex].prompt}</BodyPrompt>
        <TextScroll>
          <TextWrapper>
            <TextHeading>{heading}</TextHeading>
            {text.map((line) => <Line>{line}</Line>)}
            <Button onClick={() => navigate('')}>Start Over</Button>
          </TextWrapper>
        </TextScroll>
      </>
    );
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pagesMemo = useMemo(generatePages, [displayIndex, navigate]);

  return (
    transitions((style) => (
      <BodyWrapper style={style}>
        {pagesMemo}
      </BodyWrapper>
    ))
  );
}

export default Body;
