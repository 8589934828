/* eslint-disable react/no-unescaped-entities */
import styled from 'styled-components';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

const AlertWrapper = styled.div`
  text-align: left;
  margin: 1em;
  display: grid;
  grid-template-rows: 5em 1fr 10em;
  grid-template-columns: 1fr;
  grid-template-areas: "title"
                       "message"
                       "ok";
`;

const Button = styled.button`
  margin: .4em;
  background-color: transparent;
  border: .2em solid ${colors.mediumGrey};
  border-radius: 1em;
  color: ${colors.lightGrey};
  font-family: ${fonts.mono.fontFamily};
  text-transform: uppercase;
  font-size: 1.1em;
  height: 2.8em;
  padding-bottom: .1em;
  grid-area: ok;
  align-self: end;

  &:hover {
    background-color: ${colors.lightGrey};
    border-color: ${colors.accent};
    color: ${colors.darkGrey};
  }

  &:active {
    background-color: ${colors.mediumGrey};
    color: ${colors.primary};
  }
`;

const H1 = styled.h1`
  grid-area: 'title';
  margin: 0;
  color: ${colors.primary};
  font-family: ${fonts.regular.fontFamily};
  font-weight: ${fonts.regular.extraBoldItalic.weight};
  font-style: ${fonts.regular.extraBoldItalic.style};
  grid-area: title;
  font-size: 3em;
`;

const P = styled.p`
  margin: 0;
  line-height: 1.2em;
  font-size: 1.1em;
  grid-area: message;
`;

function Alert ({ hide }) {
  function handleAlert (e) {
    e.preventDefault();
    hide();
  }

  return (
    <AlertWrapper>
      <H1>disclaimer</H1>
      <P gridArea="message">
        WEBSITE DISCLAIMER
        <br />
        <br />
        The information provided by sndwrks LLC ("we", "us", or "our") on http://www.shouldiquarantine.com (the "Site") is for general information purposes only. All information is provided on the Site in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THIS SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE AND RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK
        <br />
        <br />
        EXTERNAL LINKS DISCLAIMER
        <br />
        <br />
        The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third-parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
      </P>
      <Button
        onClick={handleAlert}
        hoverBackground={colors.grey4}
      >
        OK
      </Button>
    </AlertWrapper>
  );
}

export default Alert;
