const fonts = {
  mono: {
    fontFamily: `bc-sklonar, Open-Sans, Helvetica, sans-serif`,
    light: {
      weight: 400,
      style: 'normal',
    },
  },
  regular: {
    fontFamily: `Bilo, Open-Sans, Helvetica, sans-serif`,
    medium: {
      weight: 600,
      style: 'normal',
    },
    extraBoldItalic: {
      weight: 800,
      style: 'italic',
    },
    blackItalic: {
      weight: 900,
      style: 'italic',
    },
  },
};

export default fonts;
