import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import colors from '../../styles/colors';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.darkGrey};
  opacity: .4;
  animation-duration: .4s;
  animation-iteration-count: both;

`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

`;

const ModalContainer = styled.div`
  z-index: 100;
  background: ${colors.darkGrey};
  border: .25em solid ${colors.accent};
  position: relative;
  margin: 2em auto;
  border-radius: 2em;
  max-width: 550px;
  flex: 1 1;
  padding: 1em;
  box-shadow: 5px 5px 30px 5px rgba(0,0,0,0.37);
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ModalCloseButton = styled.button`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: ${colors.secondary};
  cursor: pointer;
  border: none;
  background: none;
  margin-right: .1em;
`;

const ModalCloseX = styled.span`
  &:hover {
    color: ${colors.grey1};
  }
`;

function Modal ({ isShowing, hide, displayElem }) {
  return (
    isShowing
      // eslint-disable-next-line function-paren-newline
      ? ReactDOM.createPortal(
        <>
          <ModalOverlay onClick={hide} />
          <ModalWrapper tabIndex={-1} role="dialog">
            <ModalContainer>
              <ModalHeader>
                <ModalCloseButton type="button" data-dismiss="modal" onClick={hide}>
                  <ModalCloseX>&times;</ModalCloseX>
                </ModalCloseButton>
              </ModalHeader>
              {React.cloneElement(displayElem, { hide })}
            </ModalContainer>
          </ModalWrapper>
        </>, document.body) : null
  );
}

export default Modal;
