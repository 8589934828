const colors = {
  darkGrey: 'rgba(23, 26, 33, 1)',
  mediumGrey: 'rgba(97, 112, 115, 1)',
  lightGrey: 'rgba(218, 218, 218, 1)',
  primary: 'rgba(146, 188, 234, 1)',
  secondary: 'rgba(175, 179, 247, 1)',
  accent: 'rgba(112, 147, 172, 1)',
};

export default colors;
